@import "../mainVariables";
@import "../mixins";

.header-wrapper {
  background-color: white;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 10000;
  //box-shadow: 0 2px 4px 0 rgba(47, 51, 56, 0.16), inset 0 0 0 15px #fff;

  .header-box-wrapper {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 $mainPadding;

    .header-logo {
      display: flex;
      align-items: center;
      margin-right: 0;

      svg {
        height: 35px;
      }

      path {
        fill: $darkColor;
      }

      .mobile-logo {
        width: 130px;
        height: 24px;

        path {
          fill: black;
        }
      }
    }

    .right-side {
      display: flex;
      align-items: center;

      .service-center {
        @include flex-center;
        white-space: nowrap;
        color: $darkColor;
        height: 45px;
        font-size: 14px;
        margin-right: 20px;
        //font-family: Medium;
        font-weight: bold;
        letter-spacing: 0.4px;
        span {
          border-bottom: 1px dashed $darkColor;
        }

        .service-center-icon {
          margin-right: 15px;

          path {
            fill: $darkColor;
          }
        }
      }

      .apps-wrapper {
          display: flex;
          align-items: center;
        margin-right: 14px;
        a {
          width: 110px;

          &.google-play {
            width: 120px;
            svg{
              width: 114px;

            }
          }

          svg {
            margin-left: 16px;
            height: 100%;
            width: 110px;
          }
        }

      }

      .languages-wrapper {

        .language-button {
          width: 40px;
          height: 36px;
          background-color: $greyBgColor;
          @include flex-center;
          margin-left: 10px;
          border-radius: 6px;
          margin-bottom: 4px;
          font-size: 12px;
          color: #303030;
        }
      }
    }


    @media all and (max-width: 1300px) {
      .header-location {
        font-size: 14px;

        .location-icon {
          height: 26px;
          width: 28px;
        }
      }

      .apps-wrapper {
        svg {
          margin-left: 10px;
          height: 40px;
          width: 100px;
        }
      }
    }

    @media all and (max-width: 1050px) {
      display: none;
    }
  }

  .mobile-nav-bar {
    display: none;
  }

  @media all and (max-width: 1050px) {
    .mobile-nav-bar {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 46px;
      background-color: $brandColor;
      padding: 0 $mainPadding;

      .nav-bar-menu {
        $color: black;

        $height-icon: 16px;
        $width-line: 20px;
        $height-line: 2px;

        $transition-time: 0.2s;
        $rotation: 45deg;
        $translateY: ($height-icon / 2);
        $translateX: 0;

        width: $width-line;
        height: $height-icon;
        position: relative;
        display: block;
        cursor: pointer;

        .line {
          display: block;
          background: $color;
          width: $width-line;
          height: $height-line;
          position: absolute;
          left: 0;
          border-radius: 4px;
          transition: all $transition-time;
          -webkit-transition: all $transition-time;
          -moz-transition: all $transition-time;

          &.line-1 {
            top: 0;
          }

          &.line-2 {
            top: 50%;
            transform: translateY(-50%);
          }

          &.line-3 {
            top: 100%;
            transform: translateY(-100%);
          }
        }

        &:hover, &:focus {
          .line-1 {
            transform: translateY(-40%);
          }

          .line-3 {
            transform: translateY(-40%);
          }
        }

        &.active {
          .line-1 {
            transform: translateY($translateY) translateX($translateX) rotate($rotation);
            -webkit-transform: translateY($translateY) translateX($translateX) rotate($rotation);
            -moz-transform: translateY($translateY) translateX($translateX) rotate($rotation);
          }

          .line-2 {
            opacity: 0;
          }

          .line-3 {
            transform: translateY($translateY * -1) translateX($translateX) rotate($rotation * -1);
            -webkit-transform: translateY($translateY * -1) translateX($translateX) rotate($rotation * -1);
            -moz-transform: translateY($translateY * -1) translateX($translateX) rotate($rotation * -1);
          }
        }
      }

      .mobile-logo {
        margin-left: 10px;
        width: 100px;
        height: 40px;

        path, polygon {
          //fill: white;
        }
      }
    }
  }
}

