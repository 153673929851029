@import "../fonts";
@import "../mainVariables";
@import "../mixins";

.footer-wrapper {
  background-color: $greyBgColor;
  overflow: hidden;
  //display: flex;
  //justify-content: space-between;

  .desktop-footer-wrapper {
    //display: flex;
    //justify-content: space-between;

  }

  .mobile-footer-wrapper {
    display: none;
    padding: 20px $mainPadding;

    .top-part {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 20px;

      .logo-wrapper {
        width: 140px;

        img {
          width: 100%;
        }
      }

      .icons-wrapper {
        display: flex;
        align-items: center;
        height: 25px;

        a {
          margin-left: 6px;

          .social-icon {
            width: 25px;
            height: 25px;
          }
        }
      }
    }

    .ant-collapse {
      border: 0;

      .ant-collapse-item {
        border-color: $borderColor;
      }

      .ant-collapse-header {
        background-color: #F8F8F8;
        border-radius: 0;
        padding: 12px 0;
      }

      .ant-collapse-content {
        background-color: #F8F8F8;
        border-top: 0;
        border-radius: 0;

        .link-item {
          padding: 10px 0;
        }

        .ant-collapse-content-box {
          padding: 0 10px;
        }
      }
    }

    .bottom-side {
      display: flex;
      margin-top: 20px;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
    }

    .developed-by {
      display: flex;
      align-items: center;
      font-size: 12px;

      a {
        display: flex;
        align-items: center;
      }
    }

    .copyright {
      font-size: 12px;
      //margin-bottom: 10px;
    }
  }

  .row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px $mainPadding 0 $mainPadding;

    .logo-wrapper {
      svg {
        path {
          fill: $darkColor;
        }
      }
    }

    .addresses {
      display: flex;
      align-items: center;
      color: $darkColor;
      font-size: 16px;
      //width: calc(100% - 180px);
      //justify-content: flex-end;
      flex-wrap: wrap;

      .nav-item {
        margin-left: 30px;
&:hover{
  color: $brandColor;
}
      }
    }

    .social, .info {
      display: flex;
      align-items: center;
      color: $darkColor;
      font-size: 16px;
    }

    .info {
      .info-item {
        margin-right: 30px;
        display: flex;
        align-items: center;
        //font-family: Medium;
        font-weight: bold;
        letter-spacing: 0.4px;
        transition: all 0.2s;

        svg {
          margin-right: 10px;

          path {
            fill: $darkColor;
            transition: all 0.2s;
          }
        }
        &:hover{
          color: $brandColor;
          svg {
            path {
              fill: $brandColor;
            }
          }
        }
      }
    }

    .social {
      .social-title {
        margin-right: 20px;
      }

      .icons-wrapper {
        display: flex;
        align-items: center;

        a {
          width: 36px;
          height: 36px;
          border-radius: 6px;
          //background-color: $darkColor;
          @include flex-center;
          margin-left: 12px;
          overflow: hidden;
        }

        .social-icon {
          height: 26px;
        }
      }
    }
  }

  .bottom-side {
    padding: 16px $mainPadding;
    border-top: 1px solid #BEBEBE;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;
    color: $darkColor;

    .developed-by {
      display: flex;
      align-items: center;

      span {
        margin-right: 20px;
        font-size: 16px;
        //font-family: Medium;
        font-weight: 500;
        letter-spacing: 0.4px;
      }

      a {
        display: flex;
        align-items: center;
      }

      svg {
        height: 30px;
        width: 90px;
      }
    }
  }

  @media all and (max-width: 1050px) {
    flex-wrap: wrap;
    .desktop-footer-wrapper {
      //display: none;
    }
    .mobile-footer-wrapper {
      //display: block;
    }
    .row {
      padding: 20px $mainPadding 0 $mainPadding;

      .addresses {
        font-size: 14px;

        .nav-item {
          margin-left: 12px;
        }
      }

      .social, .info {
        font-size: 14px;
      }

      .info {
        .info-item {
          margin-right: 30px;
          display: flex;
          align-items: center;
          //font-family: Medium;
          font-weight: bold;
          letter-spacing: 0.4px;
          svg {
            margin-right: 10px;

            path {
              fill: $darkColor;
            }
          }
        }
      }

      .social {
        .social-title {
          margin-right: 20px;
        }

        .icons-wrapper {
          display: flex;
          align-items: center;
          .social-icon {
            height: 26px;
          }
        }
      }
    }
  }

  @media all and (max-width: 800px) {
    position: relative;
    .row {
      padding: 20px $mainPadding 0 $mainPadding;
      flex-direction: column;
      align-items: flex-start;

      .logo-wrapper {
        svg {
          width: 120px;
        }
      }

      .addresses {
        flex-wrap: wrap;
        font-size: 12px;
        margin-top: 20px;

        .nav-item {
          margin-left: 0;
          margin-right: 8px;
        }
      }

      .social, .info {
        font-size: 12px;
      }

      .info {
        flex-wrap: wrap;

        .info-item {
          margin-right: 30px;
          display: flex;
          align-items: center;
          //font-family: Medium;
          font-weight: bold;
          letter-spacing: 0.4px;

          svg {
            margin-right: 10px;

            path {
              fill: $darkColor;
            }
          }
        }
      }

      .social {
        position: absolute;
        top: 0;
        right: $mainPadding;
        margin-top: 20px;

        .social-title {
          display: none;
        }

        .icons-wrapper {

          a {
            width: 30px;
            height: 30px;
            border-radius: 6px;
            @include flex-center;
            margin-left: 12px;
          }

          .social-icon {
            height: 26px;
          }
        }
      }
    }

    .bottom-side {
      padding: 20px $mainPadding;
      flex-wrap: wrap;
      font-size: 12px;

      .developed-by {

        span {
          margin-right: 10px;
          font-size: 12px;
        }

        svg {
          height: 30px;
          width: 70px;
        }
      }
    }
  }
}