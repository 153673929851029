@import "../mainVariables";
@import "../mixins";

.services-wrapper {
  width: 100%;
  padding: 120px $mainPadding 0 $mainPadding;

  .top-side {
    width: 100%;
    display: flex;
    justify-content: space-between;
    //align-items: center;
    margin-bottom: 60px;

    .description {
      width: 56%;

      .services-title {
        width: 70%;
        color: $darkColor;
        //@include font(Bold, 40);
        font-size: 40px;
        font-weight: bold;
        letter-spacing: 0.4px;
        line-height: 54px;
        margin-bottom: 40px;
        height: 104px;
        overflow: hidden;
      }

      .services-sub-title {
        width: 90%;
        color: #3C3C44;
        font-size: 20px;
        max-height: 220px;
        overflow: hidden;
        span{
          display: none;
          color: black;
          //font-family: Medium;
          font-weight: bold;
          letter-spacing: 0.4px;
          cursor: pointer;
          margin-left: 8px;
          position: relative;
          padding-right: 25px;

          svg{
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 20px;
            height: 20px;
            path{
              fill: black;
            }
          }
        }
      }
    }

    .services-list{
      display: none;
    }
  }

  .bottom-side {
    display: flex;
    justify-content: space-between;

    .items-description {
      width: 37%;
      color: #3C3C44;
      font-size: 20px;
      transition: all 0.3s;
      height: 188px;
      overflow: hidden;

      span{
        display: none;
        color: black;
        //font-family: Medium;
        font-weight: bold;
        letter-spacing: 0.4px;
        cursor: pointer;
        margin-left: 8px;
        position: relative;
        padding-right: 25px;

        svg{
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 20px;
          height: 20px;
          path{
            fill: black;
          }
        }
      }
    }

    .selected-image{
      display: none;
    }
  }

  .selected-image {
    width: 37%;
    position: relative;

    .image-proportion {
      position: relative;
      width: 100%;
      padding-top: 72%;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 16px;
        z-index: -1;
      }
    }

    .selected-title {
      position: absolute;
      top: 20px;
      left: 20px;
      width: 50%;
      //@include font(Medium, 24);
      //@include font(Regular, 24);
      font-size: 24px;
      font-weight: bold;
      letter-spacing: 0.4px;
      color: $darkColor;
    }
  }

  .services-list {
    width: 56%;
    display: flex;
    justify-content: space-between;

    .service-card {
      width: 24%;
      border-radius: 16px;
      position: relative;
      background-color: $greyBgColor;
      transition: all 0.3s;
      cursor: pointer;
      overflow: hidden;

      .image-wrapper {
        width: 70%;
        float: right;

        .image-proportion {
          padding-bottom: 100%;
          position: relative;
          width: 100%;

          img {
            position: absolute;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
          }
        }
      }

      .service-title {
        color: $darkColor;
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 10px;
        height: 56px;
        overflow: hidden;
        padding: 20px;
      }

      &.active-card {
        background-color: $brandColor;
        background-image: url("../../../assets/images/services/background.png");
        background-size: contain;
      }
    }
  }

  @media all and (max-width: 1500px) {
    .top-side {
      .description {

        .services-title {
          font-size: 34px;
          line-height: 44px;
          height: 86px;
        }

        .services-sub-title {
          font-size: 18px;
          max-height: 194px;
        }
      }
    }

    .bottom-side {
      .items-description {
        font-size: 16px;
        height: 156px;
      }
    }

    .selected-image {

      .selected-title {
        font-size: 20px;
      }
    }

    .services-list{
      .service-card {
        .service-title {
          font-size: 13px;
          line-height: 16px;
          height: 44px;
          padding: 12px;
        }
      }
    }
  }

  @media all and (max-width: 1300px) {
    .top-side {
      margin-bottom: 40px;

      .description {
        width: 60%;

        .services-title {
          font-size: 30px;
          line-height: 44px;
          margin-bottom: 20px;
        }

        .services-sub-title {
          font-size: 16px;
          max-height: 172px;
        }
      }
    }

    .bottom-side {
      .items-description {
        font-size: 16px;
        height: 122px;
      }
    }

    .selected-image {

      .selected-title {
        font-size: 20px;
      }
    }

    .services-list{
      .service-card {
        .service-title {
          padding: 12px;
        }
      }
    }
  }

  @media all and (max-width: 1050px) {

    .top-side {
      margin-bottom: 40px;
      flex-direction: column-reverse;

      .description {
        width: 100%;
        order: 1;
        margin-bottom: 20px;

        .services-title {
          font-size: 24px;
          line-height: 30px;
          margin-bottom: 10px;
        }

        .services-sub-title {
          font-size: 14px;
          max-height: unset;
          overflow: unset;
          span{
            display: unset;
          }
        }
      }

      .services-list{
        display: flex;
        width: 100%;

        .service-card {

          .service-title {
          }

        }
      }

      .selected-image{
        display: none;
      }
    }

    .bottom-side {
      flex-direction: column;

      .items-description {
        font-size: 16px;
        order: 1;
        width: 100%;
        margin-bottom: 20px;
        span{
          display: unset;
        }
      }

      .services-list{
        display: none;
      }
      .selected-image {
        display: block;
        margin-bottom: 20px;
        width: 50%;

        .selected-title {
          font-size: 20px;
        }
      }
    }

    .selected-image{
      .image-proportion{
        img{
          border-radius: 10px;
        }
      }
    }

    .services-list{
      .service-card{
        border-radius: 10px;
      }
    }
  }

  @media all and (max-width: 700px) {
    padding: 40px $mainPadding 0 $mainPadding;
    .top-side {
      .description {
        width: 100%;
        order: 2;

        .services-title {
          font-size: 20px;
          line-height: 26px;
          margin-bottom: 10px;
        }

        .services-sub-title {
          font-size: 12px;
        }
      }

      .services-list {
        //gap: 10px;
        max-width: 100%;
        overflow-x: auto;
        scroll-snap-type: x mandatory;
        overflow-scrolling: touch;
        scroll-padding: 10px;

        &::-webkit-scrollbar {
          height: 0;
          display: none;
        }

        .service-card {
          flex: 0 0 150px;
          width: 30px;
          height: 150px;
          scroll-snap-align: start;
          margin-right: 10px;

          .service-title {
            //font-size: 13px;
            //line-height: 16px;
            //height: 40px;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    .bottom-side {
      .selected-image {
        width: 100%;

        .selected-title {
          font-size: 18px;
        }
      }

      .items-description {
        font-size: 14px;
        margin-bottom: 20px;
      }
    }
  }
}
