@import "../mainVariables";
@import "../mixins";

.fuels-wrapper {
  padding: 120px $mainPadding 0 $mainPadding;

  .fuels-title {
    //@include font(Medium, 40);
    //@include font(Regular, 40);
    font-size: 40px;
    font-weight: bold;
    letter-spacing: 0.4px;
    color: $darkColor;
    text-align: center;
    padding-bottom: 40px;
  }

  .banner-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;

    .banner {
      width: 49%;
      position: relative;

      .image-proportion {
        position: relative;
        padding-top: 50%;

        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: 16px;
          z-index: -1;
        }
      }

      .banner-content {
        width: 55%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        .title {
          //@include font(Medium, 20);
          //@include font(Regular, 20);
          font-size: 20px;
          font-weight: bold;
          letter-spacing: 0.4px;
          color: $darkColor;
          margin-bottom: 20px;
        }

        ol {
          color: $darkColor;
          font-size: 16px;
          margin-left: 12px;

          li {
            margin-bottom: 10px;
          }
        }

        &.left {
          right: 40px;
        }

        &.right {
          left: 50px;
        }
      }
    }
  }

  .fuels-list {
    display: flex;
    justify-content: space-between;

    .fuel-card {
      width: 15%;
      border-radius: 16px;
      transition: all 0.3s;
      cursor: pointer;
      overflow: hidden;
      background-color: $greyBgColor;
      position: relative;

      .card-proportion {
        width: 100%;
        position: relative;
        padding-top: 100%;
        //background-color: $greyBgColor;

        .card-inner {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
        }
      }

      .image-wrapper {
        width: 70%;
        //float: right;
        position: absolute;
        right: 0;
        bottom: -3px;
        z-index: 0;
        //transform: translateY(-100%);

        .image-proportion {
          padding-bottom: 92%;
          position: relative;
          width: 100%;
          //bottom: 0;

          img {
            position: absolute;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
            object-fit: contain;
            //z-index: 0;
            //transform: translateY(-50%);
          }
        }
      }

      .fuel-title {
        position: absolute;
        top: 0;
        color: $darkColor;
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 10px;
        height: 62px;
        overflow: hidden;
        padding: 20px;
        z-index: 1;
      }
    }
  }

  @media all and (max-width: 1500px) {
    .banner-wrapper {

      .banner {

        .banner-content {
          width: 55%;

          .title {
            font-size: 18px;
          }

          ol {
            font-size: 14px;
          }
        }
      }
    }
    .fuels-list {
      .fuel-card {
        .fuel-title {
          font-size: 15px;
        }
      }
    }
  }

  @media all and (max-width: 1300px) {
    .fuels-title {
      font-size: 34px;
    }
    .banner-wrapper {

      .banner {

        .banner-content {
          width: 60%;

          .title {
            font-size: 16px;
            margin-bottom: 10px;
          }

          ol {
            font-size: 13px;

            li {
              margin-bottom: 6px;
            }
          }

          &.left {
            right: 20px;
          }

          &.right {
            left: 30px;
          }
        }
      }
    }
    .fuels-list {
      .fuel-card {
        width: 16%;

        .fuel-title {
          font-size: 14px;
        }
      }
    }
  }

  @media all and (max-width: 1050px) {
    padding-top: 40px;
    .fuels-title {
      font-size: 26px;
      padding-bottom: 40px;
    }

    .banner-wrapper {

      flex-wrap: wrap;

      .banner {
        width: 100%;
        margin-bottom: 30px;

        .image-proportion {
          //padding-top: 49.5%;
          img {
            border-radius: 10px;
          }
        }

        .banner-content {

          .title {
            font-size: 18px;
            margin-bottom: 16px;
          }

          ol {
            font-size: 14px;

            li {
              margin-bottom: 10px;
            }
          }

          &.left {
            right: 20px;
          }

          &.right {
            left: 30px;
          }
        }
      }
    }

    .fuels-list {
      display: flex;
      //gap: 20px;
      max-width: 100%;
      overflow-x: auto;
      scroll-snap-type: x mandatory;
      overflow-scrolling: touch;
      scroll-padding: 10px;

      &::-webkit-scrollbar {
        height: 0;
        display: none;
      }

      .fuel-card {
        flex: 0 0 180px;
        width: 30px;
        height: 180px;
        scroll-snap-align: start;
        border-radius: 10px;
        margin-right: 20px;

        &:last-child {
          margin-right: 0;
        }

        .fuel-title {
          //font-size: 16px;
        }
      }
    }
  }

  @media all and (max-width: 600px) {
    padding-top: 20px;
    .fuels-title {
      font-size: 20px;
      padding-bottom: 20px;
    }

    .banner-wrapper {

      .banner {
        margin-bottom: 20px;

        .image-proportion {
          padding-top: 55.5%;
        }

        .banner-content {
          width: 68%;

          .title {
            font-size: 13px;
            margin-bottom: 10px;
          }

          ol {
            font-size: 11px;

            li {
              margin-bottom: 6px;

              span {
                max-height: 32px;
                width: 100%;
                overflow: hidden;
                display: block;
              }
            }
          }

          &.left {
            right: 10px;
          }

          &.right {
            left: 10px;
          }
        }

        @media all and (max-width: 400px) {
          .image-proportion {
            padding-top: 55.5%;
          }
          .banner-content {

            .title {
              font-size: 11px;
              margin-bottom: 6px;
            }

            ol {
              font-size: 10px;

              li {
                margin-bottom: 6px;
              }
            }
          }
        }

        &:first-child{
          //.image-proportion{
          //  background-color: $brandColor;
          //  border-radius: 10px;
          //  img{
          //    z-index: 0;
          //    object-fit: contain;
          //  }
          //}
        }
      }
    }

    .fuels-list {
      //gap: 10px;
      .fuel-card {
        margin-right: 10px;
        flex: 0 0 130px;
        height: 130px;

        .fuel-title {
          font-size: 12px;
          line-height: 16px;
          height: 68px;
          padding: 10px;
        }
      }
    }
  }

}
