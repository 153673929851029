@import "../mainVariables";
@import "../mixins";

.locations-wrapper {
  min-height: calc(100vh - 80px);
  width: 100%;
  padding-top: 110px;
  position: relative;

  .map {
    width: 100%;
    height: calc(100vh - 110px);
    position: relative;
    border-radius: 4px;

    .marker {
      svg {
        transform: translate(-50%, -100%);
      }

      &.selected {
        svg {
          path {
            fill: $brandColor;
          }
        }
      }
    }

    button {
      //left: unset!important;
      //right: $mainPadding!important;
      //margin-right: 0!important;
    }
    .gmnoprint, .gm-bundled-control, .gm-bundled-control-on-bottom{

      //.gm-control-active{
      //  right:  calc(--$mainPadding + 40px) !important;
      //  right:  $mainPadding!important;
        //right:  100px!important;
      //}
      .gmnoprint{
        //&:first-child{
        //  left: -28px!important;
        //}
      }
    }
  }


  @media screen and (max-width: 1050px) {
    padding-top: 40px;
  }

  @media screen and (max-width: 600px) {

  }
}

.station-info-box {
  width: 400px;
  border-radius: 8px;
  padding: 0;
  position: absolute;
  z-index: 10000;
  bottom: 20px;
  left: 20px;
  background-color: white;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s;

  .header {
    font-size: 16px;
    color: $darkColor;
    //font-family: Bold;
    font-weight: bold;
    letter-spacing: 0.4px;
    padding: 16px 20px;
    width: 100%;
    border-bottom: 1px solid $borderColor;
    display: flex;
    justify-content: space-between;

    .close-icon {
      cursor: pointer;
    }
  }

  .box-content {
    padding: 20px 20px 34px 20px;

    .station-address {
      font-size: 20px;
      color: $darkColor;
      //font-family: Bold;
      font-weight: bold;
      letter-spacing: 0.4px;
      margin-bottom: 30px;
    }

    .station-working-hours {
      display: flex;
      align-items: center;
      margin-top: 24px;
      color: $darkColor;
      font-size: 16px;

      svg {
        margin-right: 10px;

        path {
          //fill: #76777B;
        }
      }

    }
  }

  &.visible-box{
    visibility: visible;
    opacity: 1;
  }

  @media screen and (max-width: 700px) {
    width: calc(100% - 20px);
    left: 10px;
    bottom: 10px;
      .header {
        font-size: 16px;
        padding: 12px;
      }
    .box-content {
      padding: 16px 16px 34px 16px;

      .station-address {
        font-size: 16px;
        margin-bottom: 20px;
      }

      .station-working-hours {
        margin-top: 14px;
        font-size: 14px;

        svg {
          margin-right: 6px;

          path {
            fill: #76777B;
          }
        }

      }
    }
  }
}