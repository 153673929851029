@import "../mainVariables";
@import "../mixins";

.partners-wrapper {
  width: 100%;
  padding: 120px 0 0 $mainPadding;
  margin-bottom: 100px;

  .partners-title {
    font-size: 40px;
    //font-family: Medium;
    font-weight: bold;
    letter-spacing: 0.4px;
    color: $darkColor;
    margin: 60px 0;
    text-align: center;
  }

  .partners-list-wrapper {
    .slick-slider {
      .slick-list {
        .slick-track {
          //transform: translate3d(0px, 0px, 0px);
          //margin-left: 0;
        }
      }
    }

    .partner-item {
      //border: 1px solid $borderColor;
      border-radius: 8px;
      width: 180px;
      height: 80px;
      @include flex-center;
      .partner-content {
        img {
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }

  @media all and (max-width: 1050px) {
    padding-top: 40px;
      .partners-title {
        font-size: 25px;
        margin-top: 0;
        margin-bottom: 40px;
      }

      .partners-list-wrapper {
        .partner-item {
          width: 150px;
          height: 64px;
        }
      }
  }
  @media all and (max-width: 700px) {
    .partners-title {
      font-size: 20px;
    }
    .partners-list-wrapper {
      .partner-item {
        width: 140px;
        height: 60px;
      }
    }
  }
  @media all and (max-width: 500px) {
    .partners-list-wrapper {
      .partner-item {
        width: 110px;
        height: 50px;
      }
    }
  }
}