
:root {
  --brandColor: #FFD305;
  --brandSecColor: #ff7f45;
  --mainPadding: 100px;
  --asideContentWidth: 500px;
}

$asideContentWidth: var(--asideContentWidth);
$mainPadding: var(--mainPadding);

//--------Colors---------
$brandColor: var(--brandColor);
$brandSecColor: var(--brandSecColor);
$borderColor: #ECECF1;
$textColor: #333333;
$greyTextColor: #949BA4;
$mainBgColor: #FDFDFF;
$darkColor: #010619;

$inputBorderColor: #868686;
$inputBGColor: white;
$inputTextColor: #1D1C1D;

$greyBorderColor: #424C55;
$greyColor: #959595;
$greyBgColor: #F2F2F2;
$darkTextColor: #333333;
$lightGreyColor: #92969B;
$redColor: #F54641;


@media (max-width: 1700px) {
  :root {
    --asideContentWidth: 430px;
  }
}

@media (max-width: 1500px) {
  :root {
    --asideContentWidth: 380px;
  }
}

@media (max-width: 1400px) {
  :root {
    --mainPadding: 80px;
  }
}

@media (max-width: 1300px) {
  :root {
    --mainPadding: 60px;
    --asideContentWidth: 300px;
  }
}

@media (max-width: 1100px) {
  :root {
    --mainPadding: 40px;
  }
}


@media (max-width: 800px) {
  :root {
    --asideContentWidth: 250px;
    --mainPadding: 20px;
  }
}

@media (max-width: 600px) {
  :root {
    --mainPadding: 15px;
  }
}

@media (max-width: 500px) {
  :root {
    --mainPadding: 10px;
  }
}
