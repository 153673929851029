@import "../mainVariables";
@import "../mixins";

.app-wrapper {
  width: 100%;
  padding: 110px 0 0 0;
  min-height: 560px;

  .app-title {
    //@include font(Medium, 40);
    //@include font(Regular, 40);
    font-size: 40px;
    font-weight: bold;
    letter-spacing: 0.4px;
    margin-bottom: 40px;
    text-align: center;
    color: $darkColor;
  }

  .app-content {
    width: 100%;
    height: calc(100%);
    background: rgb(255, 211, 5);
    background: linear-gradient(97deg, rgba(255, 211, 5, 1) 0%, rgba(255, 211, 5, 1) 50%);

    .app-inner {
      background-image: url("../../../assets/images/app_bg.png");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: left;
      width: 100%;
      object-fit: cover;
      height: 100%;
      padding: 10%;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .app-info {
        //padding: 5%;
        width: 45%;
        color: $darkColor;

        .title {
          //@include font(Bold, 40);
          //@include font(Regular, 40);
          font-size: 40px;
          font-weight: bold;
          letter-spacing: 0.4px;
          margin-bottom: 30px;
        }

        p {
          font-size: 20px;
          max-height: 90px;
          overflow: hidden;
        }

        .app-buttons {
          margin-top: 60px;

          svg, img {
            width: 160px;
            height: 50px;

            &:first-child {
              margin-right: 20px;
            }
            .google-play {
              width: 168px;
              //img{
              //  max-width: 128px;
              //}
            }
          }
        }
      }
    }
  }

  @media all and (max-width: 1500px) {
    .app-content {

      .app-inner {

        .app-info {

          .title {
            font-size: 30px;
            margin-bottom: 20px;
          }

          p {
            font-size: 18px;
          }

          .app-buttons {
            margin-top: 60px;

            svg, img {
              width: 160px;
              height: 50px;

            }
          }
        }
      }
    }
  }

  @media all and (max-width: 1300px) {
    .app-content {

      .app-inner {
        padding: 10%;

        .app-info {
          //padding: 5%;
          width: 45%;
          color: $darkColor;

          .title {
            font-size: 26px;
            margin-bottom: 18px;
          }

          p {
            font-size: 16px;
            max-height: 72px;
          }

          .app-buttons {
            margin-top: 40px;

            svg, img {
              width: 140px;
              height: 40px;

              &:first-child {
                //margin-right: 20px;
              }
            }
          }
        }
      }
    }
  }

  @media all and (max-width: 1050px) {
    height: 70vh !important;
    padding: 40px 0 0 0;
    min-height: 90vh;
    .app-content {
      .app-inner {
        background-image: url("../../../assets/images/app_mobile.jpg");
        background-position: bottom;
        background-size: cover;
        padding: 6%;
        justify-content: flex-start;
        align-items: flex-start;

        .app-info {
          //padding: 5%;
          width: 100%;
          color: $darkColor;

          .title {
            font-size: 38px;
            margin-bottom: 10px;
          }

          p {
            font-size: 22px;
            max-height: 96px;
          }

          .app-buttons {
            margin-top: 30px;

            svg, img {
              width: 200px;
              height: 80px;
            }
            .google-play {
              width: 214px;
              margin-right: 10px;
              img{
                //max-width: 228px;
              }
            }
          }
        }
      }
    }
  }

  @media all and (max-width: 700px) {
    min-height: 580px;
    .app-title {
      font-size: 28px;
      margin: 20px 0;
    }

    .app-content {
      //padding-top: 58%;

      .app-inner {
        padding: 4%;
        //background-image: unset;
        //background-color: $brandColor;
        //justify-content: center;
        background-size: contain;

        .app-info {
          width: 80%;
          color: $darkColor;

          .title {
            font-size: 22px;
            //@media all and (max-width: 400px) {
            //  font-size: 16px;
            //}
          }

          p {
            font-size: 14px;
            max-height: 62px;
          }

          .app-buttons {

            svg, img {
              width: 124px;
              height: unset;

              &:first-child {
                //width: 134px;
                margin-right: $mainPadding;
              }
            }
            .google-play {
              width: 134px;
              //margin-right: $mainPadding;
              //margin-right: 10px;
              img{
                //max-width: 228px;
              }
            }
          }
        }
      }
    }
  }
}