@import "../mainVariables";
@import "../fonts";
@import "../mixins";

.navigation-wrapper {
  width: 100%;
  padding: 10px $mainPadding;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid $borderColor;

  .header-location {
    color: black;
    font-size: 15px;
    //font-family: Regular;
    display: flex;
    align-items: center;
    width: 20%;
    //transition: 0.2s;
    a{
      display: flex;
      align-items: center;
      transition: 0.2s;
    }

    .location-icon {
      margin-right: 10px;
      margin-left: 12px;
      //height: 30px;
      //width: 32px;
      path{
        transition: 0.2s;
      }
    }
    &:hover{
      color: $brandColor;
      .location-icon {
        path{
          fill: $brandColor;
        }
      }
    }
  }

  .nav-bar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 80%;

    .nav-item {
      font-size: 14px;
      color: $darkColor;
      margin-left: 40px;
      cursor: pointer;
      //font-family: Medium;
      font-weight: bold;
      letter-spacing: 0.4px;
      line-height: 16px;
      overflow: hidden;
      height: 16px;
      white-space: nowrap;
      text-overflow: ellipsis;

      &:hover {
        color: $brandColor;
      }
    }
  }

  .dropdown-menu-item {
    display: flex;
    align-items: center;
    margin-left: 15px;
    font-size: 16px;
    //@include font(Regular, 16);
    color: $brandColor;

    svg {
      path {
        fill: $brandColor;
      }
    }
  }

  @media all and (max-width: 1300px) {
    /* .top-side {
       .right-side {
         .service-center {
           font-size: 16px;
           padding-right: 10px;

           svg {
             width: 22px;
             height: 22px;
           }
         }

         .dropdown-menu-item {
           margin-left: 10px;
           font-size: 14px;
         }
       }
     }*/
    .header-location {
      font-size: 14px;

      .location-icon {
        margin-right: 6px;
        height: 24px;
        width: 26px;
      }
    }
    .nav-bar {
      .nav-item {
        font-size: 14px;
      }
    }
  }

}

@media all and (max-width: 1050px) {
  .navigation-wrapper {
    display: none;
  }
}
