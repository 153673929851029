@import "../mainVariables";
@import "../mixins";

.slider-wrapper {
  padding-top: 110px;

  .image-wrapper {
    width: 100%;
    position: relative;
    transition: all 0.2s ease-in-out;

    .slick-slider {
      height: 100%;

      .slider-item {
        position: relative;
        width: 100%;
        height: calc(100vh - 110px);
        //padding-top: 47%;
        //display: flex;
        //align-items: center;

        img {
          //position: absolute;
          //top: 0;
          //left: 0;
          //right: 0;
          //bottom: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          //z-index: -1;
        }
      }
      /// 375 * 256

      .slide-content {
        position: absolute;
        top: 50%;
        left: $mainPadding;
        z-index: 100;
        transform: translateY(-50%);
        width: 55%;
      }

      .slide-title {
        //@include font(Bold, 52);
        //@include font(Regular, 52);
        font-size: 52px;
        font-weight: bold;
        letter-spacing: 0.4px;
        overflow: hidden;
        line-height: 70px;
        height: 136px;
        color: $darkColor;
        margin-bottom: 50px;
      }

      .slide-description {
        width: 90%;
        color: $darkColor;
        //@include font(Regular, 20);
        font-size: 20px;
        line-height: 36px;
        height: 138px;
        overflow: hidden;
      }

      .player-btn {
        height: 50px;
        width: 220px;
        border-radius: 50px;
        background-color: #FFD305;
        color: $darkColor;
        @include flex-center;
        margin-top: 40px;

        svg {
          //margin-right: 8px;

          path {
            fill: $darkColor;
          }
        }
      }
    }

    &:hover {
      .arrows {
        visibility: visible;
      }
    }

    .arrow-wrapper {
      position: absolute;
      bottom: 50px;
      left: $mainPadding;
      display: flex;

      .slider-arrow {
        @include flex-center;
        margin-right: 10px;
        cursor: pointer;
        transition: all 0.3s;

        .icon-prev {
          transform: rotate(180deg);
        }

        svg {
          height: 30px;
          width: 30px;

          path {
            fill: #959595;
          }
        }

        &:hover {
          svg {
            path {
              fill: $darkColor;
            }
          }
        }
      }
    }
  }

  @media all and (max-width: 1300px) {
    .image-wrapper {
      .slick-slider {
        .slide-title {
          font-size: 40px;
          line-height: 56px;
          height: 106px;
          margin-bottom: 30px;
        }

        .slide-description {
          font-size: 16px;
          line-height: 26px;
          height: 100px;
        }

        .player-btn {
          height: 40px;
          width: 180px;
          border-radius: 50px;
          margin-top: 40px;
          font-size: 12px;

          svg {
            margin-right: 8px;
            height: 18px;

            path {
              fill: $darkColor;
            }
          }
        }
      }

      .arrow-wrapper {
        bottom: 30px;
      }
    }
  }

  @media screen and (max-width: 1050px) {
    padding-top: 0;
    //height: 60vh!important;
    .image-wrapper {
      .slick-slider {
        .slider-item{
          //height: calc(60vh - 40px);
          height: unset;
          padding-top: 68%;
          display: flex;
          align-items: center;

          img {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: -1;
          }

        }

        .slide-title {
          margin-bottom: 40px;
          //font-size: 26px;
          //line-height: 36px;
          //height: 68px;
          //margin-bottom: 10px;
        }

        .slide-description {
          //font-size: 14px;
          //line-height: 20px;
          //height: 78px;
        }

        .player-btn {
          height: 50px;
          width: 200px;
          border-radius: 50px;
          background-color: #FFD305;
          color: $darkColor;
          @include flex-center;
          margin-top: 40px;

          svg {
            margin-right: 8px;

            path {
              fill: $darkColor;
            }
          }
        }

      }

      .arrow-wrapper {
        bottom: 25px;
      }
    }
  }

  @media all and (max-width: 700px) {
    .image-wrapper {
      .slick-slider {
        .slider-item{
          padding-top: 185%;
        }
        .slide-content {
          width: 65%;
          left: 50%;
          transform: translate(-50%,-50%);
          @include flex-center;
          flex-direction: column;
        }

        .slide-title {
          font-size: 24px;
          line-height: 38px;
          height: 70px;
          margin-bottom: 36px;
          text-align: center;
        }

        .slide-description {
          width: 100%;
          font-size: 13px;
          line-height: 26px;
          height: 100px;
          text-align: center;
        }

        .player-btn {
          width: 180px;
          height: 40px;
          border-radius: 50px;
          margin-top: 44px;
          font-size: 11px;
          margin-x: auto;

          svg {
            margin-right: 4px;
            height: 16px;

            path {
              fill: $darkColor;
            }
          }
        }
      }

      .arrow-wrapper {
        bottom: 15px;

        .slider-arrow {
          width: 25px;
          height: 25px;
          margin-right: 8px;

          svg {
            height: 18px;
            width: 18px;
          }
        }
      }
    }
  }

  @media all and (max-width: 500px) {
    .image-wrapper {
      .slick-slider {
        .slide-content {
          width: 90%;
        }

        //.slide-title {
        //  font-size: 15px;
        //  line-height: 20px;
        //  height: 40px;
        //  margin-bottom: 10px;
        //}
        //
        //.slide-description {
        //  height: 46px;
        //  font-size: 11px;
        //  line-height: 16px;
        //}
        //
        //.player-btn {
        //  width: 130px;
        //  height: 34px;
        //  margin-top: 20px;
        //}
      }

      .arrow-wrapper {
        //bottom: 5px;

      }
    }
  }
}
