@import "../../mainVariables";
@import "../../mixins";
@import "../../fonts";

.link-button {
  @include flex-center;
  height: 45px;
  font-size: 17px;
  color: #fff;
  border-radius: 4px;
  padding: 0 35px;
  border: none;
  //text-transform: capitalize;
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  background-color: $brandColor;
  letter-spacing: 1px;
  //font-family: Regular;

  white-space: nowrap;

  &:disabled {
    color: #fff;
    background: #dadada !important;
    cursor: default;
  }

  &.hover-bg-change {
    &:hover {
      &:not(:disabled) {
        //background-color: $pinkColor !important;
        color: white;
      }
    }
  }

  &.xl {
    height: 45px;
    width: 160px;
    @include font(Bold, 13);
    @media all and (max-width: 420px) {
      //@include font(Medium, 12);
      //@include font(Regular, 12);
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0.4px;
      height: 35px;
      width: 120px;
    }
  }

  &.lg {
    height: 40px;
    width: 140px;
    @include font(Bold, 13);
    font-weight: 600;
    @media all and (max-width: 576px) {
      //@include font(Medium, 12);
      //@include font(Regular, 12);
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0.4px;
      height: 32px;
      width: 120px;
    }
  }

  &.md {
    height: 30px;
    width: 100px;
    @include font(Bold, 12);
    font-weight: 600;
    @media all and (max-width: 768px) and (min-width: 576px) {
      @include font(Medium, 11);
      height: 28px;
      width: 90px;
    }
    @media all and (max-width: 480px) {
      @include font(Medium, 9);
      height: 25px;
      width: 70px;
    }
  }

  &.bg-white {
    background-color: white;
    color: $brandColor;
    border: 1px solid $brandColor;

  }

  &.bg-grey {
    background-color: #DFDFDF;
    color: $brandColor;

  }

  &.bg-red {
    background-color: #FF2540;
    color: white;
    border: none;
  }

  &.bg-orange {
    background-color: #FFB03F;
    color: white;
    border: none;
  }

  .flex-row {
    display: flex;
    align-items: center;

    .ant-spin {
      margin-left: 8px;
    }
  }
}

@media screen and (max-width: 1024px) {
}

@media screen and (max-width: 800px) {
  .link-button {
    font-size: 13px;
  }
}


@media screen and (max-width: 450px) {
  .link-button {
    width: 120px;
  }
}

