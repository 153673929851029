@import "../mainVariables";
@import "../mixins";

.contacts-wrapper {
  padding: 130px $mainPadding 0 $mainPadding;
  margin-right: 40px;
  position: relative;

  .contacts-title {
    //@include font(Regular, 40);
    //@include font(Bold, 40);
    font-size: 40px;
    font-weight: bold;
    letter-spacing: 0.4px;
    color: $darkColor;
    margin-bottom: 40px;
    width: 50%;
    line-height: 56px;
    overflow: hidden;
    height: 106px;
  }

  .form-wrapper {
    width: 100%;
    height: calc(100vh - 345px);
    min-height: 480px;
    background-image: url("../../../assets/images/contacts/nk.png");
    background-size: cover;
    background-color: $brandColor;
    background-repeat: no-repeat;
    background-position: right;
    border-radius: 16px;

    .wrapper-proportion {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 0 0 3%;
    }

    .form-content {
      width: 50%;
      //height: 100%;
      background-color: $greyBgColor;
      padding: 3%;
      border-radius: 16px;

      .row {
        display: flex;
        justify-content: space-between;

        .custom-input-group {
          width: 49%;
        }
      }

      .custom-input-group {
        margin-bottom: 0;

        input, textarea {
          border-radius: 6px;
          color: #6E6E6E;

          &::placeholder {
            color: #6E6E6E;
          }
        }

        textarea {
          height: 80px;
        }
      }

      .link-button {
        width: 100%;
        border-radius: 6px;
        height: 50px;
        //@include font(Medium, 14)
        //@include font(Regular, 14);
        font-size: 14px;
        letter-spacing: 0.4px;
        font-weight: bold;
      }
      .tick-icon{
        width: 16px;
        height: 16px;
        margin-left: 10px;
        transition: all 0.2s;
        visibility: visible;
        path{
          fill: white;
        }
      }
      .show-tick{
        visibility: visible;
        opacity: 1;
      }
      .hide-tick{
        visibility: hidden;
        opacity: 0;
        //width: 0;
      }
    }

    .contacts-list {
      display: flex;
      width: 40%;
      right: -40px;
      //margin-right: auto;
      //transform:   translateX(80px);
      position: absolute;
      top: 130px;
      z-index: 2;
      flex-wrap: wrap;

      .contact-card {
        width: 30%;
        border-radius: 16px;
        position: relative;
        background-color: #f7f7f7;
        transition: all 0.3s;
        cursor: pointer;
        overflow: hidden;
        margin-bottom: 20px;
        margin-right: 20px;

        .image-wrapper {
          width: 100%;
          float: right;

          .image-proportion {
            padding-bottom: 100%;
            position: relative;
            width: 100%;

            img, svg {
              position: absolute;
              bottom: 0;
              right: 0;
              width: 100%;
              object-fit: cover;
              height: 100%;

              ellipse {
                transition: all 0.3s;
                fill: #F0EDED;
              }
            }
          }
        }

        .contact-title {
          width: 100%;
          color: $darkColor;
          font-size: 14px;
          line-height: 20px;
          margin-bottom: 10px;
          height: 56px;
          overflow: hidden;
          padding: 16px;
          position: absolute;
          bottom: 0;
        }

        &:nth-child(3) {
          margin-right: 0;
        }

        &:hover {
          .image-wrapper {
            .image-proportion {
              svg {
                ellipse {
                  fill: $brandColor;
                }

                ellipse:first-of-type {
                  fill: rgba(255, 211, 5, 0.4);
                }
                ellipse:last-of-type {
                  fill: rgba(255, 211, 5, 0.7);
                }
              }
            }
          }
        }
      }

      @media all and (max-width: 1500px) {
        right: 0px;
      }
    }
  }


  @media all and (max-width: 1400px) {
    .contacts-title {
      font-size: 34px;
      line-height: 44px;
      height: 86px;
    }

    .form-wrapper {
      .form-content {
        width: 50%;

        .custom-input-group {
          input, textarea {
            margin-bottom: 8px;
            height: 42px;

            &::placeholder {
              color: #6E6E6E;
            }
          }

          textarea {
            height: 80px;
          }
        }

        .link-button {
          height: 40px;
        }
      }

      .contacts-list {
        .contact-card {
          //width: 16%;

          .contact-title {
            font-size: 13px;
          }
        }
      }
    }
  }

  @media all and (max-width: 1200px) {

    .contacts-title {
      font-size: 30px;
      line-height: 40px;
      height: 78px;
    }

    .form-wrapper {
      .form-content {
        width: 50%;

        .custom-input-group {
          input, textarea {
            margin-bottom: 8px;
            height: 42px;

            &::placeholder {
              color: #6E6E6E;
            }
          }

          textarea {
            height: 80px;
          }
        }

        .link-button {
          height: 40px;
        }
      }

      .contacts-list {

        .contact-card {
          //width: 16%;

          .contact-title {
            font-size: 13px;
          }
        }
      }
    }
  }

  @media all and (max-width: 1050px) {
    padding-top: 60px;
    position: relative;
    .contacts-title {
      width: 100%;
      //font-size: 26px;
    }

    .form-wrapper {
      margin-top: 40px;
      height: unset;
      background-image: unset;
      background-color: unset;

      .wrapper-proportion {
        padding: unset;
        flex-direction: column-reverse;
        justify-content: space-between;
      }

      .form-content {
        width: 100%;
        border-radius: 10px;

        .custom-input-group {
          margin-bottom: 0;

          input, textarea {
            border-radius: 6px;
            color: #6E6E6E;
            font-size: 12px;

            &::placeholder {
              font-size: 12px;
            }
          }

          textarea {
            height: 80px;
          }
        }

        .link-button {
          width: 100%;
          border-radius: 6px;
          font-size: 14px;
        }
      }

      .contacts-list {
        width: 100%;
        transform: translateX(40px);
        position: relative;
        top: unset;
        right: unset;
        z-index: 2;
        flex-wrap: wrap;
        margin-bottom: 60%;

        .bg-box {
          width: 100%;
          position: absolute;
          padding-top: 100%;
          z-index: -1;
          top: 30%;
          left: -40px;

          .bg-proportion {
            border-radius: 10px;
            background-color: $brandColor;
            background-image: url("../../../assets/images/contacts/contact_mobile.jpg");
            background-repeat: no-repeat;
            background-size: cover;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
          }
        }

        .contact-card {
          width: 30%;
          border-radius: 10px;
          position: relative;
          background-color: $greyBgColor;
          transition: all 0.3s;
          cursor: pointer;
          overflow: hidden;
          margin-bottom: 20px;
          margin-right: 20px;

          .image-wrapper {
            width: 100%;
            float: right;

            .image-proportion {
              padding-bottom: 100%;
              position: relative;
              width: 100%;

              img {
                position: absolute;
                bottom: 0;
                right: 0;
                width: 100%;
                object-fit: cover;
                height: 100%;
              }
            }
          }

          .contact-title {
            color: $darkColor;
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 10px;
            height: 56px;
            overflow: hidden;
            padding: 16px;
            position: absolute;
            bottom: 0;
          }

          &:nth-child(3) {
            //margin-right: 0;
          }
        }

      }
    }
  }

  @media all and (max-width: 700px) {
    margin-right: 20px;
    .contacts-title {
      font-size: 20px;
      margin-bottom: 20px;
      line-height: 34px;
      height: 62px;
    }

    .form-wrapper {
      margin-top: 0;

      .form-content {
        margin-left: auto;
        width: calc(100% + 20px);

        .row {
          flex-direction: column;

          .custom-input-group {
            width: 100%;
          }
        }
      }

      .contacts-list {
        transform: translateX(20px);

        .bg-box {
          top: 30%;
          left: -20px;
        }

        .contact-card {
          margin-bottom: 10px;
          margin-right: 10px;
          width: calc((100% - 20px) / 3);

          .contact-title {
            font-size: 10px;
            line-height: 16px;
            height: 62px;
          }

          @media all and (max-width: 400px) {

            .contact-title {
              padding: 8px;
              font-size: 9px;
              line-height: 12px;
              height: 44px;
            }
          }
        }
      }
    }
  }
}
